<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="
        $t('cip.dc.CJudgmentTask.title.AddConfigurationJudgmentTask')
      "
      @head-cancel="cancelFn"
      @head-save="onSubmit('save')"
      @head-saveback="onSubmit('back')"
    >
    </head-layout>
    <el-form
      ref="form"
      :model="form"
      label-width="150px"
      size="mini"
      style="margin-top: 10px"
    >
      <el-form-item
        :label="$t('cip.dc.CJudgmentTask.field.QualityMonitoringTasks')"
      >
        <el-row>
          <el-col span="18">
            <el-input v-model="addform.judgeName"></el-input>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item :label="$t('cip.dc.CJudgmentTask.field.CycleSetting')">
        <el-row>
          <el-col span="18">
            <el-input
              readonly
              :value="addform.cron"
              @change="changeCron"
            ></el-input>
          </el-col>
          <el-col span="6">
            <el-button-group>
              <el-button @click="cronDialogVisible = true" size="mini">{{
                $t("datacenter.dataQuality.monitoringTask.select")
              }}</el-button>

              <el-button @click="changeSee" size="mini">{{
                seeText
              }}</el-button>
            </el-button-group>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item :label="$t('cip.dc.DComission.tenRun')" v-show="seeTen">
        <el-row>
          <el-col span="6" v-for="(item, index) in timeList" :key="index">
            <div>{{ item }}</div>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item :label="$t('cip.dc.CJudgmentTask.field.remarks')">
        <el-row>
          <el-col span="18">
            <el-input v-model="addform.remark"></el-input>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item
        :label="$t('cip.dc.MResults.field.resultsOfEnforcement')"
        v-if="$route.query.type == 'seeResult'"
      >
        <el-row>
          <el-col span="18">
            <el-input v-model="judgeResultData"></el-input>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item
        :label="$t('cip.dc.MResults.field.executionTime')"
        v-if="$route.query.type == 'seeResult'"
      >
        <el-row>
          <el-col span="18">
            <el-input v-model="createTime"></el-input>
          </el-col>
        </el-row>
      </el-form-item>

      <el-form-item>
        <el-card style="padding: 10px 20px; width: 80%">
          <el-button
            @click="addFather"
            v-if="$route.query.type !== 'seeResult'"
            >{{ $t("cip.dc.CJudgmentTask.btn.AddParent") }}</el-button
          >
          <el-table
            :data="tableData"
            ref="table"
            style="width: 100%; margin-bottom: 20px"
            row-key="id"
            border
            default-expand-all
            :tree-props="{
              children: 'childrenList',
              hasChildren: 'hasChildren',
            }"
          >
            <el-table-column prop="id" label="">
              <template slot-scope="scope">
                {{ $t("cip.dc.CJudgmentTask.field.condition") }}
              </template>
            </el-table-column>
            <el-table-column
              prop="date"
              :label="$t('cip.dc.CJudgmentTask.field.AndOrRelationship')"
            >
              <template slot-scope="scope">
                <el-select
                  clearable
                  filterable
                  class="tableSclect"
                  v-model="scope.row.judgeComposeType"
                  :placeholder="$t('cip.dc.qualityDComission.placeholder1')"
                >
                  <el-option
                    :label="$t('cip.dc.CJudgmentTask.btn.And')"
                    value="and"
                  >
                  </el-option>
                  <el-option
                    :label="$t('cip.dc.CJudgmentTask.btn.Or')"
                    value="or"
                  >
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              prop="date"
              :label="$t('cip.dc.CJudgmentTask.field.TaskID')"
            >
              <template slot-scope="scope">
                <el-select
                  clearable
                  filterable
                  v-model="scope.row.inputTaskId"
                  @change="sourceChange2"
                >
                  <el-option
                    v-for="(item, index) in TaskList"
                    :key="index"
                    :label="item.taskName"
                    :value="item.id"
                  />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              :label="
                $t('cip.dc.CJudgmentTask.field.JudgmentRelationshipEncoding')
              "
            >
              <template slot-scope="scope">
                <el-select
                  clearable
                  filterable
                  v-model="scope.row.judgeWayCode"
                >
                  <el-option
                    v-for="(item, index) in relationEnumList"
                    :key="index"
                    :label="item.name"
                    :value="item.code"
                  />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('cip.dc.CJudgmentTask.field.DeterminationType')"
            >
              <template slot-scope="scope">
                <el-select
                  clearable
                  filterable
                  class="tableSclect"
                  v-model="scope.row.judgeType"
                  :placeholder="$t('cip.dc.qualityDComission.placeholder1')"
                >
                  <el-option
                    :label="$t('cip.dc.CJudgmentTask.btn.FixedValue')"
                    value="0"
                  >
                  </el-option>
                  <el-option
                    :label="$t('cip.dc.CJudgmentTask.btn.Task')"
                    value="1"
                  >
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              :label="$t('cip.dc.CJudgmentTask.field.DetermineInputValues')"
            >
              <template slot-scope="scope">
                <div v-show="scope.row.judgeWayCode">
                  <div v-if="scope.row.judgeType == 1">
                    <el-select
                      clearable
                      filterable
                      v-model="scope.row.judgeOutputTaskId"
                      @change="sourceChange2"
                    >
                      <el-option
                        v-for="(item, index) in TaskList"
                        :key="index"
                        :label="item.taskName"
                        :value="item.id"
                      />
                    </el-select>
                  </div>

                  <div v-if="scope.row.judgeType != 1">
                    <div
                      v-if="
                        scope.row.judgeWayCode == 7 ||
                        scope.row.judgeWayCode == 8
                      "
                      style="display: flex"
                    >
                      <el-input v-model="scope.row.beginValue"></el-input>
                      <span>-</span>
                      <el-input v-model="scope.row.endValue"></el-input>
                    </div>

                    <div
                      v-if="
                        scope.row.judgeWayCode != 7 &&
                        scope.row.judgeWayCode != 8
                      "
                    >
                      <el-input v-model="scope.row.judgeValue"></el-input>
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="inputTaskValue"
              :label="$t('cip.dc.qualityDComission.inputTaskValue')"
              width="80"
              v-if="$route.query.type == 'seeResult'"
            >
            </el-table-column>
            <el-table-column
              prop="judgeCompareResultName"
              :label="$t('cip.dc.qualityDComission.judgeCompareResultName')"
              width="80"
              v-if="$route.query.type == 'seeResult'"
            >
              <template slot-scope="scope">
                {{ scope.row.judgeCompareResultName }}
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              :label="$t('cip.dc.qualityDComission.caozuo')"
              v-if="$route.query.type !== 'seeResult'"
            >
              <template slot-scope="scope">
                <el-button type="text" @click="addChildren(scope.row)">{{
                  $t("cip.dc.CJudgmentTask.btn.AddChild")
                }}</el-button>
                <el-button type="text" @click="delChildren(scope.row)"
                  >{{ $t('cip.dc.apiDComission.del') }}</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-form-item>
      <!-- <el-form-item
        :label="$t('cip.dc.CJudgmentTask.msg.msg1')"
        v-if="$route.query.type !== 'seeResult'"
      >
        <el-radio-group v-model="addform.runType">
          <el-radio :label="1">{{
            $t("cip.dc.CJudgmentTask.field.ExecuteImmediately")
          }}</el-radio>
          <el-radio :label="2">{{
            $t("cip.dc.CJudgmentTask.field.ManualExecution")
          }}</el-radio>
        </el-radio-group>
      </el-form-item> -->
      <!-- <el-form-item v-if="$route.query.type !== 'seeResult'">
        <el-button type="primary" @click="onSubmit">{{
          $t("cip.cmn.btn.saveBtn")
        }}</el-button>
      </el-form-item> -->
    </el-form>
    <el-dialog
      :title="$t('datacenter.dataQuality.monitoringTask.cycleSetting')"
      :visible.sync="cronDialogVisible"
    >
      <cron v-model="cron" class="cron"></cron>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="cronDialogConfirm">{{
          $t("submitText")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import cron from "@/views/components/cron";
import cronParser from "cron-parser";

import {
  SaveJudgeRecord,
  checkTaskList,
  relationEnum,
  SeeJudge,
  JudgeResult,
  jxCron,
} from "@/api/dataAcquisition/index";
export default {
  data() {
    return {
      seeTen: false,
      seeText: this.$t('cip.dc.qualityDComission.see'),
      nextOccurrences: [],
      headBtnOptions: [
        {
          label: this.$t("cip.cmn.btn.saveBtn"),
          emit: "head-save",
          type: "button",
          icon: "",
          disabled: this.SeeType(),
        },
        {
          label: this.$t("cip.cmn.btn.saveBackBtn"),
          emit: "head-saveback",
          type: "button",
          icon: "",
          disabled: this.SeeType(),
        },
        {
          label: this.$t("cip.cmn.btn.celBtn"),
          emit: "head-cancel",
          type: "button",
          icon: "",
        },
      ],
      TaskList: [],
      relationEnumList: [],
      tableID: 0,
      judgeResultData: "",
      createTime: "",
      timeList: [],

      tableData: [
        // {
        //   id: 0,
        //   judgeComposeType: "",
        //   inputTaskId: "",
        //   judgeWayCode: "",
        //   judgeValue: "",
        //   judgeType:"",
        //   judgeOutputTaskId:"",
        //   beginValue:"",
        //   endValue:"",
        //   childrenList: [],
        // },
      ],
      id: this.$route.query.id,
      addform: {
        remark: "",
        judgeName: "", //判定方法名称
        cron: "", //cron 表达式
        runType: 1, //（执行类型 1-立即执行 2-手动执行）
        judgeContents: {},
      },
      cron: "",
      cronDialogVisible: false,
      templateSelection: [],
      radio: "",
    };
  },
  components: { cron },
  methods: {
    MjxCron(cron, count) {
      jxCron({ cron: cron, count: count }).then((res) => {
        this.timeList = res.data.data;
      });
    },

    changeSee() {
      this.seeTen = !this.seeTen;
      if (this.seeTen) {
        this.seeText = this.$t('cip.dc.qualityDComission.zhedie');
      } else {
        this.seeText = this.$t('cip.dc.qualityDComission.see');
      }
    },

    //扁平化 树
    flatten(arr) {
      let result = [];
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].childrenList && arr[i].childrenList.length > 0) {
          result = result.concat(this.flatten(arr[i].childrenList));
          arr[i].childrenList = [];
          result = result.concat(arr[i]);
        } else {
          result.push(arr[i]);
        }
      }
      return result;
    },
    cronDialogConfirm() {
      this.addform.cron = this.cron;
      this.cronDialogVisible = false;
      this.timeList = [];
      this.MjxCron(this.cron, 10);
    },
    SeeType() {
      if (
        this.$route.query.type == "seeResult" ||
        this.$route.query.type == "see2"
      ) {
        return true;
      } else {
        return false;
      }
    },

    //任务列表
    McheckTaskList() {
      checkTaskList({ taskName: "" }).then((res) => {
        this.TaskList = res.data.data;
      });
    },
    //判定关系编码
    MrelationEnum() {
      relationEnum({}).then((res) => {
        this.relationEnumList = res.data.data;
      });
    },
    addFather() {
            this.tableID++;
      this.tableData.push({
        id: this.tableID,
        pid: 0,
        judgeComposeType: "",
        inputTaskId: "",
        judgeWayCode: "",
        judgeValue: "",
        judgeType: "",
        judgeOutputTaskId: "",
        beginValue: "",
        endValue: "",
        childrenList: [],
      });
    },
    //新增子项
    addChildren(e) {
      this.tableID++;
      if (e.childrenList.length > 0) {
        e.childrenList.push({
          pid: e.id,
          id: this.tableID,
          judgeComposeType: "",
          inputTaskId: "",
          judgeWayCode: "",
          judgeValue: "",
          judgeType: "",
          judgeOutputTaskId: "",
          beginValue: "",
          endValue: "",
          childrenList: [],
        });
        let arr = e.childrenList;
        this.$set(e, "childrenList", arr);
      } else {
        e.childrenList.push({
          pid: e.id,
          id: this.tableID + 1,
          judgeComposeType: "",
          inputTaskId: "",
          judgeWayCode: "",
          judgeValue: "",
          judgeType: "",
          judgeOutputTaskId: "",
          beginValue: "",
          endValue: "",
          childrenList: [],
        });
        let arr = e.childrenList;
        this.$set(e, "childrenList", arr);
      }
    },
    //删除
    delChildren(e) {
      this.removeNodeInTree(this.tableData, e.id);
    },

    removeNodeInTree(treeList, id) {
      // 通过id从数组（树结构）中移除元素
      if (!treeList || !treeList.length) {
        return;
      }
      for (let i = 0; i < treeList.length; i++) {
        if (treeList[i].id === id) {
          treeList.splice(i, 1);
          break;
        }
        this.removeNodeInTree(treeList[i].childrenList, id);
      }
    },

    cancelFn() {
      this.$router.go(-1);
    },

    //保存
    onSubmit(e) {
      this.addform.judgeContents.childrenList = this.tableData;
      if (this.$route.query.type == "see") {
        this.addform.id = this.$route.query.id;
      }
      SaveJudgeRecord(this.addform).then((res) => {
        this.$message({
          message: this.$t('cip.dc.offlineDComission.savemsg'),
          type: "success",
        });
        if (e == "back") {
          //  this.$router.go(-1);
          this.$router.$avueRouter.closeTag();
          this.$router.back();
          // this.$router.push({
          //   path:"/Quality/testingTask"
          // })
        }
      });
    },
  },
  mounted() {
    this.SeeType();
    this.McheckTaskList();
    this.MrelationEnum();

    if (this.$route.query.type == "see" || this.$route.query.type == "see2") {
      SeeJudge({ id: this.$route.query.id }).then((res) => {
        this.addform.remark = res.data.data.remark;
        this.addform.judgeName = res.data.data.judgeName;
        this.addform.cron = res.data.data.cron;
        this.addform.runType = res.data.data.runType;
        this.tableData = res.data.data.judgeContents.childrenList;
        this.cron = res.data.data.cron;
        let arr = this.flatten(this.tableData);
        let arr2 = [];
        arr.forEach((element) => {
          arr2.push(element.id);
        });
        let aa = Math.max.apply(null, arr2);
        this.tableID = aa;
        this.MjxCron(this.cron, 10);
      });
    }

    if (this.$route.query.type == "seeResult") {
      JudgeResult({ id: this.$route.query.id }).then((res) => {
        this.addform.remark = res.data.data.remark;
        this.addform.judgeName = res.data.data.judgeName;
        this.addform.cron = res.data.data.cron;
        this.addform.runType = res.data.data.runType;
        this.tableData = res.data.data.judgeContents.childrenList;
        this.cron = res.data.data.cron;
        this.judgeResultData = res.data.data.judgeResult;
        this.createTime = res.data.data.createTime;
        this.MjxCron(this.cron, 10);
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.cron::v-deep {
  .el-tabs--border-card > .el-tabs__content {
    padding: 15px !important;
    background: #ffffff;
  }
}
.el-radio-group {
  line-height: 20px !important;
}
</style>
