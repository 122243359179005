var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title": _vm.$t(
            "cip.dc.CJudgmentTask.title.AddConfigurationJudgmentTask"
          ),
        },
        on: {
          "head-cancel": _vm.cancelFn,
          "head-save": function ($event) {
            return _vm.onSubmit("save")
          },
          "head-saveback": function ($event) {
            return _vm.onSubmit("back")
          },
        },
      }),
      _c(
        "el-form",
        {
          ref: "form",
          staticStyle: { "margin-top": "10px" },
          attrs: { model: _vm.form, "label-width": "150px", size: "mini" },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t(
                  "cip.dc.CJudgmentTask.field.QualityMonitoringTasks"
                ),
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: "18" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.addform.judgeName,
                          callback: function ($$v) {
                            _vm.$set(_vm.addform, "judgeName", $$v)
                          },
                          expression: "addform.judgeName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("cip.dc.CJudgmentTask.field.CycleSetting"),
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: "18" } },
                    [
                      _c("el-input", {
                        attrs: { readonly: "", value: _vm.addform.cron },
                        on: { change: _vm.changeCron },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: "6" } },
                    [
                      _c(
                        "el-button-group",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini" },
                              on: {
                                click: function ($event) {
                                  _vm.cronDialogVisible = true
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(
                                    "datacenter.dataQuality.monitoringTask.select"
                                  )
                                )
                              ),
                            ]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini" },
                              on: { click: _vm.changeSee },
                            },
                            [_vm._v(_vm._s(_vm.seeText))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.seeTen,
                  expression: "seeTen",
                },
              ],
              attrs: { label: _vm.$t("cip.dc.DComission.tenRun") },
            },
            [
              _c(
                "el-row",
                _vm._l(_vm.timeList, function (item, index) {
                  return _c("el-col", { key: index, attrs: { span: "6" } }, [
                    _c("div", [_vm._v(_vm._s(item))]),
                  ])
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("cip.dc.CJudgmentTask.field.remarks") } },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: "18" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.addform.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.addform, "remark", $$v)
                          },
                          expression: "addform.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.$route.query.type == "seeResult"
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("cip.dc.MResults.field.resultsOfEnforcement"),
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: "18" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.judgeResultData,
                              callback: function ($$v) {
                                _vm.judgeResultData = $$v
                              },
                              expression: "judgeResultData",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.$route.query.type == "seeResult"
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("cip.dc.MResults.field.executionTime"),
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: "18" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.createTime,
                              callback: function ($$v) {
                                _vm.createTime = $$v
                              },
                              expression: "createTime",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            [
              _c(
                "el-card",
                { staticStyle: { padding: "10px 20px", width: "80%" } },
                [
                  _vm.$route.query.type !== "seeResult"
                    ? _c("el-button", { on: { click: _vm.addFather } }, [
                        _vm._v(
                          _vm._s(_vm.$t("cip.dc.CJudgmentTask.btn.AddParent"))
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    "el-table",
                    {
                      ref: "table",
                      staticStyle: { width: "100%", "margin-bottom": "20px" },
                      attrs: {
                        data: _vm.tableData,
                        "row-key": "id",
                        border: "",
                        "default-expand-all": "",
                        "tree-props": {
                          children: "childrenList",
                          hasChildren: "hasChildren",
                        },
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { prop: "id", label: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "cip.dc.CJudgmentTask.field.condition"
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "date",
                          label: _vm.$t(
                            "cip.dc.CJudgmentTask.field.AndOrRelationship"
                          ),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "tableSclect",
                                    attrs: {
                                      clearable: "",
                                      filterable: "",
                                      placeholder: _vm.$t(
                                        "cip.dc.qualityDComission.placeholder1"
                                      ),
                                    },
                                    model: {
                                      value: scope.row.judgeComposeType,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          scope.row,
                                          "judgeComposeType",
                                          $$v
                                        )
                                      },
                                      expression: "scope.row.judgeComposeType",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: {
                                        label: _vm.$t(
                                          "cip.dc.CJudgmentTask.btn.And"
                                        ),
                                        value: "and",
                                      },
                                    }),
                                    _c("el-option", {
                                      attrs: {
                                        label: _vm.$t(
                                          "cip.dc.CJudgmentTask.btn.Or"
                                        ),
                                        value: "or",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "date",
                          label: _vm.$t("cip.dc.CJudgmentTask.field.TaskID"),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { clearable: "", filterable: "" },
                                    on: { change: _vm.sourceChange2 },
                                    model: {
                                      value: scope.row.inputTaskId,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "inputTaskId", $$v)
                                      },
                                      expression: "scope.row.inputTaskId",
                                    },
                                  },
                                  _vm._l(_vm.TaskList, function (item, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        label: item.taskName,
                                        value: item.id,
                                      },
                                    })
                                  }),
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "name",
                          label: _vm.$t(
                            "cip.dc.CJudgmentTask.field.JudgmentRelationshipEncoding"
                          ),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-select",
                                  {
                                    attrs: { clearable: "", filterable: "" },
                                    model: {
                                      value: scope.row.judgeWayCode,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "judgeWayCode", $$v)
                                      },
                                      expression: "scope.row.judgeWayCode",
                                    },
                                  },
                                  _vm._l(
                                    _vm.relationEnumList,
                                    function (item, index) {
                                      return _c("el-option", {
                                        key: index,
                                        attrs: {
                                          label: item.name,
                                          value: item.code,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: _vm.$t(
                            "cip.dc.CJudgmentTask.field.DeterminationType"
                          ),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-select",
                                  {
                                    staticClass: "tableSclect",
                                    attrs: {
                                      clearable: "",
                                      filterable: "",
                                      placeholder: _vm.$t(
                                        "cip.dc.qualityDComission.placeholder1"
                                      ),
                                    },
                                    model: {
                                      value: scope.row.judgeType,
                                      callback: function ($$v) {
                                        _vm.$set(scope.row, "judgeType", $$v)
                                      },
                                      expression: "scope.row.judgeType",
                                    },
                                  },
                                  [
                                    _c("el-option", {
                                      attrs: {
                                        label: _vm.$t(
                                          "cip.dc.CJudgmentTask.btn.FixedValue"
                                        ),
                                        value: "0",
                                      },
                                    }),
                                    _c("el-option", {
                                      attrs: {
                                        label: _vm.$t(
                                          "cip.dc.CJudgmentTask.btn.Task"
                                        ),
                                        value: "1",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "address",
                          label: _vm.$t(
                            "cip.dc.CJudgmentTask.field.DetermineInputValues"
                          ),
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: scope.row.judgeWayCode,
                                        expression: "scope.row.judgeWayCode",
                                      },
                                    ],
                                  },
                                  [
                                    scope.row.judgeType == 1
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                attrs: {
                                                  clearable: "",
                                                  filterable: "",
                                                },
                                                on: {
                                                  change: _vm.sourceChange2,
                                                },
                                                model: {
                                                  value:
                                                    scope.row.judgeOutputTaskId,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "judgeOutputTaskId",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.judgeOutputTaskId",
                                                },
                                              },
                                              _vm._l(
                                                _vm.TaskList,
                                                function (item, index) {
                                                  return _c("el-option", {
                                                    key: index,
                                                    attrs: {
                                                      label: item.taskName,
                                                      value: item.id,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    scope.row.judgeType != 1
                                      ? _c("div", [
                                          scope.row.judgeWayCode == 7 ||
                                          scope.row.judgeWayCode == 8
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    model: {
                                                      value:
                                                        scope.row.beginValue,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "beginValue",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.beginValue",
                                                    },
                                                  }),
                                                  _c("span", [_vm._v("-")]),
                                                  _c("el-input", {
                                                    model: {
                                                      value: scope.row.endValue,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "endValue",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.endValue",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          scope.row.judgeWayCode != 7 &&
                                          scope.row.judgeWayCode != 8
                                            ? _c(
                                                "div",
                                                [
                                                  _c("el-input", {
                                                    model: {
                                                      value:
                                                        scope.row.judgeValue,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "judgeValue",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.judgeValue",
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm.$route.query.type == "seeResult"
                        ? _c("el-table-column", {
                            attrs: {
                              prop: "inputTaskValue",
                              label: _vm.$t(
                                "cip.dc.qualityDComission.inputTaskValue"
                              ),
                              width: "80",
                            },
                          })
                        : _vm._e(),
                      _vm.$route.query.type == "seeResult"
                        ? _c("el-table-column", {
                            attrs: {
                              prop: "judgeCompareResultName",
                              label: _vm.$t(
                                "cip.dc.qualityDComission.judgeCompareResultName"
                              ),
                              width: "80",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            scope.row.judgeCompareResultName
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2845085581
                            ),
                          })
                        : _vm._e(),
                      _vm.$route.query.type !== "seeResult"
                        ? _c("el-table-column", {
                            attrs: {
                              prop: "address",
                              label: _vm.$t("cip.dc.qualityDComission.caozuo"),
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.addChildren(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "cip.dc.CJudgmentTask.btn.AddChild"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.delChildren(scope.row)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("cip.dc.apiDComission.del")
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              801459508
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.$t("datacenter.dataQuality.monitoringTask.cycleSetting"),
            visible: _vm.cronDialogVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.cronDialogVisible = $event
            },
          },
        },
        [
          _c("cron", {
            staticClass: "cron",
            model: {
              value: _vm.cron,
              callback: function ($$v) {
                _vm.cron = $$v
              },
              expression: "cron",
            },
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.cronDialogConfirm },
                },
                [_vm._v(_vm._s(_vm.$t("submitText")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }